<template>
  <div class="app-logo-container" :class="[theme, size]">
    <div class="main-line">
      <a :href="appConfig.wp_url">
        <img :width="logoSize" :src="logoSrc" alt="fiveblocks" />
      </a>
    </div>
  </div>
</template>

<script>
import appConfig from "@/../config/config.json";

export default {
  name: "AppLogo",
  props: {
    theme: {
      type: String,
      required: true,
    },
    size: {
      type: String,
      required: true,
    }
  },
  data() {
    return {
      appConfig: appConfig,
    };
  },  
  computed: {
    color() {
      return {
        light: 'white',
        dark: 'black',
      }[this.theme];
    },
    logoSize() {
      return {
        small: 185,
        large: 246,
      }[this.size];
    },
    logoSrc() {
      return this.theme === 'light'
        ? require('@/assets/images/fiveblocks-logo-white.svg')
        : require('@/assets/images/fiveblocks-logo-black.svg');
    },
  },
};
</script>

<style lang="scss" scoped>
.app-logo-container {
  text-align: left;

  &.large {
    --fb-offset: 5px;
  }

  &.small {
    --fb-offset: 1px;
  }

  &.light {
    color: white;

    .beta-logo {
      color: white;
      background-color: var(--chart-blue9);
    }
  }

  &.dark {
    color: black;

    .beta-logo {
      color: white;
      background-color: black;
    }
  }

  &.large {
    .main-line {
      font-size: 36px;
    }
  }

  &.small {
    .main-line {
      font-size: 24px;
    }
  }
}

.main-line {
  display: flex;
  align-items: center;
  font-weight: 500;
}

img {
  margin: 0 3px;
}

a {
  text-decoration: none;
  color: inherit;
}
</style>
