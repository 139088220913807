<template>
  <div class="chart-engine-model-compare" v-if="responsesWithTag.length">
    <span class="title">Compare #{{ aiTag.tag.toLowerCase() }} across all AI models</span>
    <div class="chart-container" v-if="chartDataForTag?.labels">
      <Line :data="chartDataForTag" :options="chartOptionsForTag" />
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Line } from "vue-chartjs";
import { AiModelDisplayNames } from "@/constants";

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

export default {
  data() {
    return {
      chartDataForTag: [],
    };
  },
  components: {
    Line,
  },
  props: {
    responsesWithTag: Array,
  },
  computed: {
    ...mapGetters("ai", {
      aiTag: "aiTag",
      aiPromptGroup: "aiPromptGroup",
      aiEngineModel: "aiEngineModel",
    }),
    chartOptionsForTag() {
      return {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
          legend: {
            display: true,
            position: "bottom", // Move legend to the bottom
            labels: {
              fontColor: "#004CDE",
            },
          },
        },
        scales: {
          x: {
            grid: {
              display: false,
            },
          },
          y: {
            beginAtZero: true,
          },
        },
      };
    },
  },
  methods: {
    orderByDate(groupedData) {
      const orderedData = groupedData.sort((a, b) => {
        if (a === b) return 0;
        if (a < b) return -1;
        else return 1;
      });
      return orderedData;
    },
    groupDataForModelType(data, promptGroupId, modelType, tagId, uniqueDates) {
      const groupedData = uniqueDates.reduce((result, date) => {
        const recordsForDate = data.filter(
          (record) =>
            record.ai_prompt_group_id === promptGroupId &&
            record.model_type === modelType &&
            record.tags.some((tag) => tag.ai_prompt_template_tag_id === tagId) &&
            record.datetime.split("T")[0] === date
        );
        result[date] = recordsForDate.length;
        return result;
      }, {});

      return groupedData;
    },
    prepareChartDataForTag() {
      // Get unique model types from the data
      const uniqueModelTypes = [
        ...new Set(this.responsesWithTag.map((record) => record.model_type)),
      ];

      const allDates = this.responsesWithTag.map((record) => record.datetime.split("T")[0]);
      const uniqueDates = this.orderByDate([...new Set(allDates)]);

      // Create datasets for each model type
      const datasets = uniqueModelTypes.map((modelType) => {
        const modelTypeData = this.groupDataForModelType(
          this.responsesWithTag,
          this.aiPromptGroup.id,
          modelType,
          this.aiTag.ai_prompt_template_tag_id,
          uniqueDates
        );

        let borderWidth = uniqueDates.length === 1 ? 5 : 2;
        let pointRadius = uniqueDates.length === 1 ? 3 : 5; 

        // If there's only one date, make sure it appears as a single point
        if (uniqueDates.length === 1) {
          return {
            label: AiModelDisplayNames[modelType], // Use the model type as the label
            data: [Object.values(modelTypeData)[0]], // Use a single value for the data array
            fill: false,
            borderColor: `rgba(${Math.random() * 255}, ${Math.random() * 255}, ${Math.random() * 255
              }, 1)`,
            borderWidth: borderWidth, 
            pointRadius: pointRadius,
            pointBackgroundColor: `rgba(${Math.random() * 255}, ${Math.random() * 255}, ${Math.random() * 255
              }, 1)`,
          };
        } else {
          return {
            label: AiModelDisplayNames[modelType], // Use the model type as the label
            data: Object.values(modelTypeData),
            fill: false,
            borderColor: `rgba(${Math.random() * 255}, ${Math.random() * 255}, ${Math.random() * 255
              }, 1)`,
            borderWidth: borderWidth,
            pointRadius: pointRadius,
            lineTension: 0.4,
            pointBackgroundColor: `rgba(${Math.random() * 255}, ${Math.random() * 255}, ${Math.random() * 255
              }, 1)`,
          };
        }
      });

      return {
        labels: uniqueDates,
        datasets: datasets,
      };
    },

  },
  watch: {
    responsesWithTag(currentValue) {
      if (currentValue?.length) {
        this.chartDataForTag = this.prepareChartDataForTag();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/scss/_mixins.scss";

.chart-engine-model-compare {
  display: flex;
  width: 546px;
  padding: 24px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 16px;

  border-radius: 20px;
  border: 1px solid var(--grey2);
  background: var(--white);

  .title {
    color: var(--grey9);
    @include h3-style;
  }
}
</style>
