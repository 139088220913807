<template>
  <div v-if="groupedResponse" class="response-card mt-3">
    <card-header :dateHeader="groupedResponse.key"
                 :groupedData="groupedResponse.responses"
                 :showOpenAll="isAllOpen ? 'up' : 'down'"
                 @openAllClick="toggleOpenAll()" />

    <div v-for="(response, index) in consolidatedResponse"
         class="response-wrapper"
         :class="{ 'is-response-open': isOpen(index) }"
         :key="index">
      <div class="response-question" @click="toggleResponseOpen(index)">
        {{ response.ai_prompt }}
        <div class="open-response-handle icons arrow"
             :class="'arrow-' + (isOpen(index) ? 'up' : 'down')"></div>

      </div>
      <response-card-item class="response"
                          :class="{ 'is-response-open': isOpen(index) }"
                          :response="response"
                          :routeback="routeback" />
    </div>
  </div>
</template>

<script>
import CardHeader from "@/components/card-elements/CardHeader.vue";
import ResponseCardItem from "@/components/card-elements/ResponseCardItem.vue";
import aiUtil from "@/utils/aiUtil.js";
import { mapGetters, mapActions } from "vuex";

export default {
  props: ["groupedResponse", "routeback"],
  data() {
    return {
      aiUtil,
    };
  },
  components: {
    ResponseCardItem,
    CardHeader
  },
  computed: {
    ...mapGetters("ai", {
      neutralThreshold: "neutralThreshold",
      openResponses: "openResponses",
    }),
    isAllOpen() {
      return this.groupedResponse?.responses?.length === this.openResponses.length;
    },
    consolidatedResponse() {
      if (!this.groupedResponse || this.groupedResponse.responses.length <= 1) {
        return this.groupedResponse.responses;
      }

      const responses = this.groupedResponse.responses;
      const firstResponse = { ...responses[0] };

      firstResponse.ai_prompt = responses.map(r => r.ai_prompt).join('\n');

      // Filter out blank responses before joining
      const nonBlankContents = responses.map(r => r.content).filter(content => content.trim() !== '');      
      firstResponse.content = nonBlankContents.join('\n').replace(/\n/g, '<br>');
      
      firstResponse.sentiments = responses.flatMap(r => r.sentiments);
      firstResponse.domain = [...new Set(responses.flatMap(r => r.domain))];
      firstResponse.tags = responses.flatMap(r => r.tags);
      firstResponse.references = [...new Set(responses.flatMap(r => r.references))];

      return [firstResponse];
    }
  },
  methods: {
    ...mapActions("ai", ["toggleResponseOpen", "setOpenResponses"]),

    isOpen(responseIndex) {
      return this.openResponses.includes(responseIndex);
    },

    toggleOpenAll() {
      this.setOpenResponses(this.isAllOpen ? [] : [...Array(this.groupedResponse?.responses?.length).keys()]);
    }
  },
  watch: {},
  mounted() { },
};
</script>

<style lang="scss" scoped>
@import "@/scss/_icons.scss";
@import "@/scss/_mixins.scss";

.response-card {
  width: 32rem;

  .response-wrapper {

    .response-question {
      @include body3;
      background: var(--grey3);
      padding: .5rem 2rem .5rem .5rem;
      margin-top: 1rem;
      color: var(--primary-blue5);
      cursor: default;
      position: sticky;
      top: 56px;
      z-index: calc(var(--z-sticky-baseline) + 0);
      white-space: pre-line;
      &:hover .open-response-handle:after {
        border-color: var(--icon-blue);
      }

      .open-response-handle {
        position: absolute;
        right: 0rem;
        top: .8rem;

        &:after {
          border-color: var(--grey7);
        }

      }
    }

    &:not(.is-response-open) {
      .response-question {
        border-bottom: 1px solid var(--grey4);
      }

      .response {
        opacity: 0;
        max-height: 0;
        padding: 0;
        transition: padding .1s ease;
      }
    }

    .response {
      padding: .5rem;
      border-bottom: 1px solid var(--grey3);
      background: var(--White, #fff);
      transition: opacity .2s ease, max-height .2s cubic-bezier(0, 1, 0, 1);
    //  max-height: 100rem;

      .response-item-container {
        padding: 0 0.5rem;
        max-width: 100%;

        &.negative {
          background: #ffd5d7;
        }

        &:last-child {
          border-bottom: none;
        }
      }

      .accordion-item {
        border: 0;
      }

      .accordion-button {
        border: 0;
        padding: .5rem;
        font-size: 12px;
        color: var(--primary-blue5);
        background-color: var(--grey2);
      }
    }
  }
}
</style>
