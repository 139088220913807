<template>
  <div class="dropdown d-flex flex-column">
    <span v-if="showTitle" class="title">{{ dropdownName }}</span>
    <button class="btn btn-secondary" :class="{ [dropdownButton]: true, 'is-tag-drilldown': isTagDrilldown }"
      type=" button" data-bs-toggle="dropdown" aria-expanded="false">
      <div v-if="showIcon" class="show-icons">
        <span class="icons" :class="selectedValue" />
        <div :class="{ [dropdownDisplay]: true, 'tag-drilldown-display': isTagDrilldown }">
          {{ displayValue || selectedValue }}
        </div>
      </div>
      <div v-else :class="{ [dropdownDisplay]: true, 'tag-drilldown-display': isTagDrilldown }">
        {{ displayValue || selectedValue }}
      </div>
      <div class="icons dropdown-arrow"></div>
    </button>
    <slot name="dropdown-content"></slot>
  </div>
</template>

<script>

export default {
  props: ["showTitle", "dropdownName", "selectedValue", "dropdownButton", "dropdownDisplay", "isTagDrilldown", "showIcon", "displayValue",],
};
</script>

<style lang="scss" scoped>
@import "@/scss/_mixins.scss";
@import "@/scss/icons.scss";

.dropdown {
  position: relative;
}

.title {
  @include title-styles;
  margin-left: 3px;
  color: var(--Primary, #545f71);
  font-size: 12px;
  font-weight: 400;
  line-height: 19px;
  letter-spacing: -0.24px;
}

.btn-secondary:focus {
  background: none;
  border: none;
  box-shadow: none;
}

.btn-secondary:hover {
  background: var(--white, #fff);
  border: 1px solid var(--grey3, #e9eaed);
  box-shadow: none;
}

.btn.prompt-dropdown-button {
  display: inline-flex;
  min-width: 33.5rem;
  max-width: 45rem;
  height: 3rem;
  padding: 1.25rem 1.5rem;
  justify-content: space-between;
  align-items: center;
  gap: 1rem;
  border-radius: 1.875rem;
  border: 1px solid var(--grey3, #e9eaed);
  background: var(--white, #fff);
  align-self: stretch;
  border: 1px solid var(--grey3, #E9EAED);
  background: var(--white, #FFF);
  overflow: hidden;
}


.prompt-dropdown-button:focus {
  border: 1px solid var(--grey3, #e9eaed);
  background: var(--white, #fff);
}

.prompt-dropdown-button:hover {
  border: 1px solid var(--grey3, #e9eaed);
  background: var(--white, #fff);
}

.prompt-dropdown-display {
  min-width: 29rem;
  max-width: 41.5rem;
  padding: 0.75rem 0;
  align-items: center;
  background: transparent;
  color: var(--grey9, #17191C);
  @include body3;
  font-weight: 500;
  text-align: left;
  overflow: hidden;
  text-overflow: ellipsis;
}

.btn.client-dropdown-button {
  display: flex;
  min-width: 15rem;
  width: fit-content;
  height: 3.75rem;
  justify-content: space-between;
  align-items: center;
  gap: 1rem;
  align-self: stretch;
  border-radius: 2.5rem;
  border: 1.5px solid var(--blue3, #D3DEEE);
  background: var(--grey1, #F6F6F6);

  &.is-tag-drilldown {
    height: 3rem;
    background: var(--white, #fff);
    border: 1px solid var(--grey3, #e9eaed);
  }
}

.is-tag-drilldown.client-dropdown-display {
  font-size: 1rem;
}

.client-dropdown-button:focus {
  border-radius: 1.875rem;
  border: 1.5px solid var(--blue3, #D3DEEE);
  background: var(--grey1, #F6F6F6);
}

.client-dropdown-display {
  color: var(--grey9, #17191C);
  margin-left: .8rem;
  font-family: 'Open Sans';
  font-size: 2rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 30rem;

  &.tag-drilldown-display {
    font-size: 1rem;
  }
}



.btn.ai-models-dropdown-button {
  display: flex;
  width: 13.75rem;
  height: 3rem;
  padding: 0.75rem 1rem;
  justify-content: space-between;
  align-items: center;
  border-radius: 1.875rem;
  border: 1px solid var(--grey3, #e9eaed);
  background: var(--white, #fff);
}

.show-icons {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.ai-models-dropdown-button:focus {
  border: 1px solid var(--grey3, #e9eaed);
  background: var(--white, #fff);
  box-shadow: none;
}

.ai-models-dropdown-button:hover {
  border: 1px solid var(--grey3, #e9eaed);
  background: var(--white, #fff);
  box-shadow: none;
}

.ai-models-dropdown-display {
  color: var(--grey9, #17191c);
  @include body3;
  font-size: 1rem;
  font-weight: 500;
}

.btn.date-range-dropdown-button {
  display: flex;
  width: 11.25rem;
  justify-content: space-between;
  align-items: center;
  height: 3rem;
  padding: 0.75rem 1rem;
  border-radius: 1.875rem;
  border: 1px solid var(--grey3, #e9eaed);
  background: var(--white, #fff);
}

.date-range-dropdown-display {
  flex: 1 0 0;
  width: 100%;
  color: var(--grey9, #17191c);
  /* Body/Body 3/Body 14 Medium */
  font-family: Inter;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 500;
  line-height: 150%;
  /* 1.3125rem */
}

.dropdown-arrow {
  display: flex;
  width: 1.5rem;
  height: 1.5rem;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  margin: .15rem 0;
}
</style>
