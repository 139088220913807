
export function applyBardFormatting(content) {

  // Define an array of formatting rules with explanatory comments
  const formattingRules = [

    {
      regex: /\*\*(.*?)\*\*/g,
      replacement: '$1',
      description: 'Apply bold formatting to text enclosed in double asterisks (**text**).'
    },
    // {
    //   regex: /\*\*([^*]+):/g,
    //   replacement: '$1:',
    //   description: 'Apply bold formatting to text enclosed in double asterisks followed by a colon (**text:**).'
    // },
    {
      regex: /(:)(?!\s)/g,
      replacement: '$1 ',
      description: 'Add a space after a colon if there isn\'t one already.'
    },
    // {
    //   "regex": /(\*\s*<span class="[^"]*">.*?<\/span>\s*:)/g,
    //   "replacement": '<br><br>$1',
    //   "description": 'Add <br><br> at the beginning of patterns like "* <span class="positive-text">Declining theme park attendance</span>:".'
    // },    
    {
      "regex": /(\d+\.\s*<[^>]+>.*?<\/[^>]+>)/g,
      "replacement": '<br><br>$1',
      "description": 'Add <br><br> at the beginning of patterns like "3. <b>Improving customer service</b>".'
    },
    {
      "regex": /([*-])\s/g,
      "replacement": '<br><br>$1 ',
      "description": 'Add <br><br> before every occurrence of an asterisk * or a dash - followed by a space.'
    },
    {
      "regex": /(\s*[-]\s*<span class="[^"]*">.*?<\/span>)/g,
      "replacement": '<br><br>$1',
      "description": 'Add <br><br> at the beginning of patterns starting with a dash or an asterisk, like "- <span class="positive-text">Labor disputes</span>" or "* <span class="positive-text">Labor disputes</span>".'
    },
    {
      regex: /(?:<b>|###|-|\s*)References\s*(?:<\/b>|\$)/gi,
      replacement: '',
      description: 'Remove the specific pattern <b>References: </b>.'
    },
    {
      regex: /<b>References: <\/b>/gi,
      replacement: '',
      description: 'Remove the specific pattern <b>References: </b>.'
    },
    {
      regex: /\* \[[^\]]+\]\([^)]+\)/g,
      replacement: '',
      description: 'Remove specific patterns for any Markdown links.'
    },
    {
      regex: /[^\s]+\s*[a-zA-Z]+:\s*\/\/\S+(?:\s+\S+)*/g,
      replacement: '',
      description: 'Remove URLs starting with a number and followed by https:// or http://.'
    },
    {
      regex: /-+\s*\|\s*-+/g,
      replacement: '',
      description: 'Replace table header rows with varying amounts of dash characters with a <br> tag.'
    },
    {
      regex: /\s*\|\s*/g,
      replacement: ' - ',
      description: 'Replace table header rows with varying amounts of dash characters with a <br> tag.'
    },
    {
      regex: /-(\s*-)+/g,
      replacement: '',
      description: 'Remove patterns of dashes like -  - or more.'
    },

    {
      regex: /-\s*<b>/g,
      replacement: '<br><br><b>',
      description: 'Change -<strong> to <br><strong>.'
    },
    {
      "regex": /(<(strong|b)>\d+\.\s.*?<\/\2>)/g,
      "replacement": '<br><br>$1',
      "description": 'Add <br> at the beginning of <strong> followed by a number and period.'
    },
    {
      "regex": /(<b>\*\s.*?:\s*<\/b>)/g,
      "replacement": '<br><br>$1',
      "description": 'Add <br> at the beginning of <b>* followed by text and a colon.</b>.'
    },
    {
      regex: /(<br>\s*){3,}/g,
      replacement: '<br><br>',
      description: 'Replace more than two consecutive <br> tags with exactly two <br> tags.'
    },

  ];

  // Apply each formatting rule in a loop
  let formattedContent = content;
  formattingRules.forEach(rule => {
    formattedContent = formattedContent.replace(rule.regex, rule.replacement);
  });

  return formattedContent;
}

export function applyAIOverviewFormatting(content) {

  const formattingRules = [
    // {
    //   regex: /^([^:]+:)(.*)/gm,
    //   replacement: '<strong>$1</strong><br>$2',
    //   description: 'Wrap any text followed by a colon in bold tags and add a new line.'
    // },
    {
      regex: /(\/n)+/g,
      replacement: '<br>',
      description: 'Replace multiple consecutive /n with a single <br> tag.'
    },
    {
      regex: / (—) /gm,
      replacement: '<br><br>$1 ',
      description: 'Add a <br> tag at the beginning of any line that starts with an em dash (—).'
    },
    {
      regex: /(<span[^>]*>.*?<\/span>\r?:)/g,
      replacement: '<br><br>$1',
      description: 'Add <br><br> at the beginning of a pattern where there is a <span> tag followed by a closing </span> tag, a carriage return, and a colon.'
    },
    {
      regex: /^: (<span[^>]*>)/gm,
      replacement: '<br>$1',
      description: 'Add a <br> tag at the beginning of a string that starts with a colon and a space followed by a <span> tag.'
    }


  ];


  // Apply each formatting rule in a loop
  let formattedContent = content;
  formattingRules.forEach(rule => {
    formattedContent = formattedContent.replace(rule.regex, rule.replacement);
  });

  return formattedContent;
}

export function applyChatGPTFormatting(content) {

  const formattingRules = [
    {
      regex: /\*\*(.*?)\*\*/g,
      replacement: '$1',
      description: 'fix text enclosed in double asterisks (**text**).'
    },
    
    // {
    //   "regex": /\*\*<span class="([^"]*)">(.*?)<\/span>\*\*:/g,
    //   "replacement": '<span class="$1">$2</span>:',
    //   "description": 'Remove ** from around <span class="...">...</span>:.'
    // },
    {
      "regex": /(\d+\.\s*<b>.*?<\/b>)/g,
      "replacement": '<br><br>$1',
      "description": 'Add <br><br> at the beginning of patterns like "5. <b>Cultural Homogenization</b>".'
    },
    
    {
      "regex": /(\d+[.)]\s*<span class="[^"]*">.*?<\/span>\s*:)/g,
      "replacement": '<br><br>$1',
      "description": 'Add <br> at the beginning of patterns like "1. <span class="positive-text">Financial Struggles</span>:".'
    },
    {
      "regex": /(\d+\)\s*<span class="[^"]*">.*?<\/span>\s*\.)/g,
      "replacement": '<br><br>$1',
      "description": 'Add <br> at the beginning of patterns like "5) <span class="positive-text">Rising costs related to content production and acquiring licenses for intellectual properties, which pressure financial performance</span>.".'
    }


  ];


  // Apply each formatting rule in a loop
  let formattedContent = content;
  formattingRules.forEach(rule => {
    formattedContent = formattedContent.replace(rule.regex, rule.replacement);
  });

  return formattedContent;
}

export function applyCoPilotFormatting(content) {

  const formattingRules = [
    {
      "regex": /(\d+\.\s*|\.\s*)([A-Za-z\s]+:)/g,
      "replacement": '<br><br>$2',
      "description": 'Add <br><br> before any text that follows a colon and is preceded by a period and a space, or before any number followed by a period and a space, and followed by a colon.'
    },
    {
      "regex": /\*\*([\s\S]*?)\*\*/g,
      "replacement": '$1',
      "description": 'Remove any pattern that contains **.'
    },
    {
      "regex": /\b(?:https?:\/\/)?(?:www\.)?[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}(?:\/[^\s]*)?\b/g,
      "replacement": '',
      "description": 'Remove URLs at the end of the text.'
    },
    {
      "regex": /(\d+\.\s*<[^>]+>.*?<\/[^>]+>)/g,
      "replacement": '<br><br>$1',
      "description": 'Add <br><br> at the beginning of patterns like "3. <b>Improving customer service</b>".'
    },    


    







  ];


  // Apply each formatting rule in a loop
  let formattedContent = content;
  formattingRules.forEach(rule => {
    formattedContent = formattedContent.replace(rule.regex, rule.replacement);
  });

  return formattedContent;
}