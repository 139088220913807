<template>
  <div class="ai-saas root-container">
    <div class="layout-component">
      <div class="left-section">
        <div class="left-section-contents-wrapper">
          <!-- Slot for specific page content -->
          <slot name="content" />
        </div>
      </div>
      <div class="right-section">
        <!-- <div class="images pie"></div> -->
        <div class="title-container">
          <div class="title">
            <span>AI Reputation </span>
            <span>Starts with Research.</span>
            <span class="use-aiq">Use AIQ.</span>
          </div>
          <div class="sub-title">
            <span>AI reputation intelligence management system.</span>
          </div>
        </div>
        <!-- <div class="images-container">
          <img src="/aisaas/static/images/login-bg.svg" alt="analysis" class="image-analysis" />
        </div> -->
        <!-- <div class="bar">
          <div class="one optional-bar" />
          <div class="two optional-bar" />
          <div class="three optional-bar" />
          <div class="four" />
          <div class="five" />
          <div class="six" />
          <div class="seven" />
          <div class="eight" />
        </div> -->
      </div>
    </div>
  </div>
</template>

<script>
export default {
};
</script>


<style scoped lang="scss">
@import "@/scss/_icons.scss";
@import "@/scss/_mixins.scss";
@import "@/scss/_ai-saas-common.scss";


.root-container {

  background-color: var(--grey1);
  height: 100vh;
  overflow: hidden;

  .layout-component {
    max-height: 100vh;

    background: var(--login-background);
    display: flex;

    .left-section {
      padding: 1rem;
      min-width: 42rem;
      display: inline-flex;
      flex-grow: 1;
      flex-direction: column;
      align-items: end;
      justify-content: center;
      height: 100vh;


      .left-section-contents-wrapper {
        padding-bottom: 10rem;
        background: var(--Additional-White, #fff);
        border-radius: 1.875rem;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100vh;
        width: 100%;
        max-width: 65rem;
      }
    }

    .right-section {
      position: relative;

      flex-grow: 1;
      max-width: 65rem;
      margin: 1rem 0 0 1rem;
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      .pie {
        position: absolute;
        backdrop-filter: blur(60.798118591308594px);
        z-index: 0;
        left: 25.2rem;
        top: 6.5rem;
      }

      .title-container {
        position: relative;

        .title {
          position: relative;
          display: flex;
          flex-direction: column;
          color: var(--Additional-White, #fff);
          font-weight: 400;

          margin-top: 4rem;
          line-height: 120%;
          font-size: 2.5rem;
          font-style: normal;
          font-family: "Open Sans";

          .use-aiq {
            color: var(--Chart-Blue-1, #d5e3ff);
            font-weight: 900;
          }
        }

        .sub-title {
          color: var(--Greyscale-Grey3, #e9eaed);
          margin-top: 1rem;
          @include body2;
        }
      }

      .images-container {
        z-index: 1;
        width: 100%;
        overflow: hidden;
        margin-top: -11rem;
        margin-left: 10rem;
        max-height: 60rem;
      }

      .bar {
        position: absolute;
        bottom: 1.5rem;
        display: flex;
        gap: 0.8rem;
        align-items: end;

        div {
          border-radius: 0.125rem;
          background: var(--chart-bar-color);
          display: flex;

          &.one {
            height: 10.4375rem;
            width: 0.875rem;
          }

          &.two {
            height: 9.19081rem;
            width: 0.8355rem;
          }

          &.three {
            height: 7.78081rem;
            width: 0.88775rem;
          }

          &.four {
            height: 6.84088rem;
            width: 0.88775rem;
          }

          &.five {
            height: 5.4375rem;
            width: 0.875rem;
          }

          &.six {
            height: 4.1875rem;
            width: 0.8125rem;
          }

          &.seven {
            height: 3rem;
            width: 0.8125rem;
          }

          &.eight {
            height: 1.6875rem;
            width: 0.875rem;
          }
        }
      }
    }

    @media screen and (max-height: 880px) {
      .left-section .left-section-contents-wrapper {
        padding-bottom: unset;
      }

      .right-section .bar .optional-bar {
        display: none;
      }
    }

    @media screen and (max-width: 840px) {
      .right-section {
        display: none;
      }
    }

    @media screen and (max-width: 900px) {
      .right-section {
        margin: 0;

        .title-container .title {
          font-size: 2rem;
        }
      }
    }

    @media screen and (max-width: 1400px) {
      .left-section {
        min-width: 30rem;
      }

      .right-section {
        max-width: 40rem;
      }

    }

    @media screen and (max-width: 500px) {
      .left-section {
        min-width: unset;
        padding: 0;

        .left-section-contents-wrapper {
          border-radius: 0;
        }
      }
    }
  }
}
</style>