<template>
  <div class="container">
    <div class="row">
      <div class="form-group col">
        <div class="form-field">
          <div class="icon-title">
            <label>
              Topic Name
              <i class="bi bi-info-circle topic-name" :class="{ 'show': showTopicNameInfo }"
                @click="showTopicNameInfo = true" v-click-outside="hideTopicNameInfo"></i>
            </label>
          </div>
          <input type="text" class="form-control" v-model="displayNameData" />
          <div class="error-message" :class="{ 'visible': validationErrors.topicName }">Topic name is required</div>

        </div>
        <div class="form-field">
          <div class="icon-title">
            <label>
              Entity
              <i class="bi bi-info-circle entity-name" :class="{ 'show': showEntityHelpInfo }"
                @click="showEntityHelpInfo = true" v-click-outside="hideEntityHelpInfo"></i>
            </label>
          </div>
          <input type="text" class="form-control" v-model="selectedAiPromptGroup" />
          <div class="error-message" :class="{ 'visible': validationErrors.entity }">Entity is required</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { AiModelDisplayNames } from "@/constants";
import vClickOutside from "click-outside-vue3";

export default {
  props: [
    "selectedAiPromptGroupProp",
    "displayName",
    "validationErrors"
  ],
  emits: ["handlePromptGroupChange", "handleDisplayNameChange"],
  directives: {
    clickOutside: vClickOutside.directive,
  },
  data() {
    return {
      isOpen: false,
      showTopicNameInfo: false,
      showEntityHelpInfo: false
    };
  },
  components: {},
  methods: {
    hideTopicNameInfo() {
      this.showTopicNameInfo = false;
    },
    hideEntityHelpInfo() {
      this.showEntityHelpInfo = false;
    },
    getModelName(modelType) {
      return AiModelDisplayNames[modelType];
    },
    toggleAccordion() {
      this.isOpen = !this.isOpen;
    },
  },
  computed: {
    selectedAiPromptGroup: {
      get() {
        return this.selectedAiPromptGroupProp;
      },
      set(value) {
        this.$emit("handlePromptGroupChange", value);
      },
    },
    displayNameData: {
      get() {
        return this.displayName;
      },
      set(value) {
        this.$emit("handleDisplayNameChange", value);
      },
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/scss/_mixins.scss";
@import "@/scss/icons.scss";
@import "@/scss/dialog-prompt-group-model.scss";

.form-group {
  gap:0;
}

.topic-name {
  position: relative;
  cursor: pointer;

  &.show:after {
    content: 'The topic of interest e.g. Best Buy Employer Brand, AT&T Stock Performance, etc.';
    display: block;
    position: absolute;
    width: 23rem;
    top: 1rem;
    left: 1rem;
    border: solid 1px black;
    box-shadow: 0px 0px 15px var(--popup-shadow-color);
    padding: 2px;
    color: white;
    background-color: black;
    font-style: normal;
    z-index: calc(var(--z-popup-overlay) + var(--z-raise-more));
  }
}

.entity-name {
  position: relative;
  cursor: pointer;

  &.show:after {
    content: 'The Company, Brand, or Individual. (e.g. Best Buy, AT&T, etc.)';
    display: block;
    position: absolute;
    width: 23rem;
    top: 1rem;
    left: 1rem;
    border: solid 1px black;
    box-shadow: 0px 0px 15px var(--popup-shadow-color);
    padding: 2px;
    color: white;
    background-color: black;
    font-style: normal;
    z-index: calc(var(--z-popup-overlay) + var(--z-raise-more));
  }
}

.form-field {
  width: 100%;
}

.error-message {
  color: var(--red2);
  @include body3;
  font-size: .70rem;
  visibility: hidden; 
  
  &.visible {
    visibility: visible;
  }
}
</style>
