<template>
  <SecurityLayout>
    <template v-slot:content>
      <div class="login-form">
        <div class="logo-container text-center">
           <app-logo theme="dark" size="large" class="app-logo" />
        </div>

        <div class="sign-in-text">
          <span>Sign in to your account</span>
        </div>

        <form class="form-login" @keyup.enter="submitForm">
          <div v-if="loginFailed" class="text-center" id="login-failed">
            Your Email or Password is incorrect. Please try again.
          </div>

          <div class="form-group input-field email">
            <label>User name</label>
            <input
                   id="email"
                   type="text"
                   name="email"
                   class="form-control"
                   :class="{ 'is-invalid': !isEmailValid }"
                   v-model="email"
                   autocomplete="username" />
          </div>

          <div class="password-container">
            <div class="form-group input-field email">
              <label>Password</label>
              <input
                     id="password"
                     v-model="password"
                     :type="showPassword ? 'text' : 'password'"
                     name="password"
                     class="form-control"
                     :class="{ 'is-invalid': !isPasswordValid }"
                     autocomplete="current-password" />
              <div class="icon-container" @click="toggleShowPassword">
                <span class="icons eye" />
              </div>
            </div>
            <div class="forgot-password">
              <router-link to="/forgot-password" class="forgot-password-link">Forgot your password?</router-link>
            </div>
          </div>
          <div class="form-item">
            <button class="btn bright-blue" @click="submitForm" :disabled="isLoading">
              <span v-if="isLoading"> <i class="fa fa-spinner fa-spin mr-1"></i></span>
              <span class="button-text">Sign In</span>
            </button>
          </div>
          <div class="or">
            <span>or</span>
          </div>
        </form>
        <div ref="googleLoginBtn"></div>
        <div class="contact-us-message">
          If you do not yet have an AIQ account, please <br><a :href="`${appConfig.wp_url}/contact-us`" >contact us</a> to discuss which plan best suits your needs.
        </div>
      </div>
    </template>
  </SecurityLayout>
</template>

<script>
import appConfig from "@/../config/config.json";
import SecurityLayout from "@/views/SecurityLayout.vue";
import AppLogo from "@/components/AppLogo";
import storage from "@/utils/storageUtil";

export default {
  components: {
    SecurityLayout,
    AppLogo,
  },
  data() {
    return {
      loginFailed: false,
      email: "",
      password: "",
      isEmailValid: true,
      isPasswordValid: true,
      isLoading: false,
      showPassword: false,
      loadGoogleTimer: false,
      appConfig: appConfig,
    };
  },
  mounted() {
    this.loadGoogleTimer = setInterval(this.loadGoogle, 100);
  },
  computed: {
  },
  methods: {
    loadGoogle() {
      if (!window.google) {
        return;
      }

      clearInterval(this.loadGoogleTimer);
      window.google.accounts.id.initialize({
        client_id: appConfig.gClientId,
        use_fedcm_for_prompt: true,
        callback: this.handleCredentialResponse,
        auto_select: true,
      });
      window.google.accounts.id.renderButton(this.$refs.googleLoginBtn, {
        text: "signin_with", // or 'signup_with' | 'continue_with' | 'signin'
        size: "large", // or 'small' | 'medium'
        width: "366", // max width 400
        theme: "outline", // or 'filled_black' |  'filled_blue'
        logo_alignment: "left", // or 'center'
      });
    },
    async submitForm() {
      this.validateFields();

      if (this.isEmailValid && this.isPasswordValid) {
        this.isLoading = true;
        this.$api.login(this.email, this.password)
          .then(this.handleLoginResponse)
          .catch((err) => {
            console.error(err);
            this.loginFailed = true;
          })
          .finally(() => {
            this.isLoading = false;
          });
      }
    },
    async handleCredentialResponse(response) {
      this.$api.tokenlogin(response.credential)
        .then(this.handleLoginResponse)
        .catch((err) => {
          console.error(err);
          this.loginFailed = true;
        });
    },
    handleLoginResponse(res) {
      if (res.data.status === "ok") {
        const href = storage.get('previousUrl') || "/";
        
        window.location.href = href;
      } else {
        this.loginFailed = true;
      }
    },
    validateFields() {
      // Email validation
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      this.isEmailValid = emailRegex.test(this.email);

      // Password validation
      this.isPasswordValid = this.password.length >= 8;
    },
    toggleShowPassword() {
      this.showPassword = !this.showPassword;
    },
  },
  watch: {},
};
</script>

<style scoped lang="scss">
@import "@/scss/_icons.scss";
@import "@/scss/_mixins.scss";
@import "@/scss/_ai-saas-common.scss";

.root-container {

  a.logo {
    text-decoration: none;
  }

  .layout-component {
    .login-form {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 1rem;
      width: 22.75rem;

      .sign-in-text {
        color: var(--grey9);
        @include h1-style;
        font-size: 1.5rem;
      }

      #login-failed {
        color: red;
      }

      h1 {
        font-size: 24px;
        font-weight: 800;
        color: #0e3e67;
        text-transform: uppercase;
      }

      button {
        font-size: 20px;
        width: 290px;
        line-height: 40px;
        height: 40px;
        padding: 0 20px;
        border: 0;
      }

      .forgot-password {
        text-align: end;

        .forgot-password-link {
          color: var(--primary-blue5);
          text-align: center;
          @include link1-style;
          text-decoration-line: underline;
        }
      }

      .dark-blue {
        margin-top: 10px;
      }

      .form-login {
        width: 300px;
        gap: 1rem;
        display: flex;
        flex-direction: column;

        .input-field {
          position: relative;

          input {
            flex: 1 0 0;
            color: var(--grey7);
            @include body3;
          }

          .icon-container {
            position: absolute;
            right: 0.6rem;
            top: 2rem;
          }

          label {
            display: flex;
            width: 22.5rem;
            flex-direction: column;
            justify-content: flex-end;
            align-items: flex-start;
            gap: 0.25rem;
          }
        }
      }

      .form-item {
        label {
          display: block;
          font-size: 18px;
          color: #073155;
          margin-bottom: 5px;
        }

        .form-control {
          width: 100%;
        }
      }

      .or {
        color: var(--grey8);
        text-align: center;
        @include body3;
      }

      .contact-us-message {
        color: var(--grey8);
        text-align: center;
        @include body3;

        a {
          color: var(--primary-blue5);
          text-decoration: underline;
        }
      }

      .is-invalid {
        border-color: #dc3545;
      }

      .invalid-feedback {
        color: #dc3545;
      }
    }
  }
}
</style>
