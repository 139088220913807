<template>
  <view-wrapper>
    <div class="prompt-groups container">
      <dialog-delete-modal @confirm="confirmDeletePromptGroup" @setShowFor="showDeleteDialogFor = $event"
        :show-for="showDeleteDialogFor" />
      <dialog-prompt-group-modal @clearMode="promptGroupDialogMode = ''" @upsertPromptGroup="upsertPromptGroup"
        :mode="promptGroupDialogMode" :aiPromptGroup="currentPromptGroup" />

      <div class="header-section">
        <div>
          <h3>Manage Topics</h3>
          <span class="m-1 subtitle">Set up and edit your topics</span>
        </div>
        <div class="right-buttons">
          <form-button @click="addPromptGroup()">
            <i class="bi bi-plus"></i> Add AI Topic
          </form-button>
        </div>
      </div>

      <div v-if="showReachedMaxMessage" class="modal fade show" id="limitReachedModal" tabindex="-1" role="dialog"
        aria-labelledby="limitReachedModalLabel" aria-hidden="true" style="display: block;">
        <div class="modal-dialog" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="limitReachedModalLabel">Topic Limit Reached</h5>

            </div>
            <div class="modal-body">
              <div>
                <span>
                  You have reached the limit of topics for<br>
                  this AIQ package. Please either delete a <br>
                  topic or <a :href="`${appConfig.wp_url}/contact-us`">contact</a> Five Blocks for assistance.
                </span>
              </div>
            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-secondary" @click="closeModal">Close</button>
            </div>
          </div>
        </div>
      </div>

      <div v-if="promptGroups.length > 0" class="table-wrapper">
        <table class="table">
          <thead>
            <tr>
              <th />
              <th scope="col" class="topic">Topic</th>
              <th scope="col">AI Search Models</th>
              <th scope="col">Peers</th>
              <th scope="col">Prompts</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(promptGroup, index) in promptGroups" :key="index">
              <td class="delete-handle">
                <span class="icons trash" @click="showDelete(promptGroup)" />
                <span v-if="!isPaused(promptGroup.polling_status)" title="pause topic" class="toggle-pause icons pause"
                  @click="togglePauseTopic(promptGroup)" />
                <span v-if="isPaused(promptGroup.polling_status)" title="unpause topic" class="toggle-pause icons play"
                  @click="togglePauseTopic(promptGroup)" />

              </td>
              <th scope="row" class="topic editable" @click.stop="editField(promptGroup, 'topic')">
                <div class="keyword" @click="routeOnClick(promptGroup)">
                  <i class="bi bi-box-arrow-up-right leave-indicator"></i>
                  <div class="name-wrapper">
                    <span>{{ promptGroup.display_name }}</span>
                    <div class="status">{{ displayPollingStatus(promptGroup) }}</div>
                  </div>
                </div>
                <span class="icons pencil" />
              </th>
              <td class="search-models editable" @click="editField(promptGroup, 'engines')">
                <engine-list :models="promptGroup.models" />
                <span class="icons pencil" />
              </td>
              <td class="peers editable" @click="editField(promptGroup, 'peers')">
                {{ formattedPeers(promptGroup) }}
                <i v-if="!formattedPeers(promptGroup)" class="bi bi-plus-circle-dotted add-peers"></i>
                <span class="icons pencil" />
              </td>
              <td class="prompts editable" @click="editField(promptGroup, 'prompts')">
                <span class="icons pencil" />
                <div class="prompts-wrapper">
                  <div class="prompt" v-for="prompt in promptGroup?.prompts" :key="prompt">{{ prompt }}</div>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div v-if="promptGroups.length == 0 && !loadingPromptGroups" class="welcome-message">
        <div>Welcome to AIQ. </div>
        <div>Click the "Add AI Entity" button on the top right to get started with adding entities to track.</div>

      </div>

    </div>
  </view-wrapper>
</template>


<script>
import { mapActions, mapGetters } from "vuex";
import DialogDeleteModal from "@/components/modals/DialogDeleteModal.vue";
import DialogPromptGroupModal from "@/components/modals/DialogPromptGroupModal/index.vue";
import useRouteHelper from "@/composables/useRouteHelper";
import EngineList from "@/components/prompt-groups/EngineList.vue";
import ViewWrapper from "@/views/ViewWrapper.vue";
import { ROUTE } from "@/constants";
import FormButton from "@/components/form-elements/FormButton.vue";
import appConfig from "@/../config/config.json";

export default {
  components: {
    DialogDeleteModal,
    DialogPromptGroupModal,
    ViewWrapper,
    EngineList,
    FormButton,
  },
  data() {
    return {
      promptGroups: [],
      showDeleteDialogFor: "",
      loadingPromptGroups: true,
      promptGroupDialogMode: '',
      currentPromptGroup: {},
      route: ROUTE,
      statusTimer: [],
      showReachedMaxMessage: false,
      appConfig: appConfig,
    };
  },
  methods: {
    ...mapActions("ai", ["initializeAI", "fetchPromptGroups", "setAiPromptGroup", "setOpenResponses", "fetchAccountMax"]),
    ...mapActions("general", [
      "setShowProgress",
      "setInProgress",
      "setView",
    ]),
    displayPollingStatus(promptGroup) {
      if (promptGroup.polling_status === 'waiting to queue') {
        return 'waiting to poll';
      }
      return promptGroup.polling_status;
    },
    confirmDeletePromptGroup() {
      if (this.currentPromptGroup?.ai_prompt_group_id === undefined) {
        // TODO is this necessary?
        return;
      }

      if (this.aiPromptGroup.id == this.currentPromptGroup?.ai_prompt_group_id) {
        this.setAiPromptGroup(null);
      }

      this.$api.deleteAiPromptGroup(this.currentPromptGroup.ai_prompt_group_id)
        .then((res) => {
          this.setShowProgress(true);
          if (!res?.data) {
            Promise.reject("Delete topic failed");
          }
          this.$notifier.info({ message: "Topic deleted" });
        })
        .then(() => {
          this.showReachedMaxMessage = false;
          this.getPromptGroups();
          this.fetchPromptGroups();
          this.setShowProgress(false);
        })
        .catch((e) => {
          this.$notifier.error({ message: "Delete topic failed", e });
          this.setInProgress(false);
        });
    },
    showDelete(promptGroup) {
      this.currentPromptGroup.ai_prompt_group_id = promptGroup.id;
      this.showDeleteDialogFor = promptGroup.name;
    },
    isPaused(promptGroupStatus) {
      if (typeof promptGroupStatus !== "string") { return true }
      else {
        return promptGroupStatus.includes("paused")
      }
    },
    togglePauseTopic(promptGroup) {
      if (promptGroup.polling_status.includes("paused")) {
        this.$api
          .unPausePromptGroup(promptGroup.id)
          .then(() => {
            this.getPollingStatus(promptGroup)
            this.$notifier.info({ message: promptGroup.display_name + " has been unpaused" })

          })
          .catch((e) => {
            this.$notifier.error({ message: "An error occurred trying to unpause", e })
          });

      } else {
        this.$api
          .pausePromptGroup(promptGroup.id)
          .then(() => {
            this.getPollingStatus(promptGroup)
            this.$notifier.info({ message: promptGroup.display_name + " has been paused" })
          })
          .catch((e) => {
            this.$notifier.error({ message: "An error occurred trying to pause", e })
          });
      }


    },

    editField(promptGroup, type) {
      this.$api
        .getAiPromptGroupConfig(promptGroup.id)
        .then((res) => {
          if (res?.data) {
            this.currentPromptGroup = res.data;
            this.currentPromptGroup.display_name = promptGroup.display_name;
            this.promptGroupDialogMode = type;
          }
        })
        .catch((e) => {
          this.$notifier.error({ message: "An error occurred trying to edit", e })
        });
    },
    addPromptGroup() {
      if (this.aiPromptGroups.length >= this.maxPromptGroups) {
        this.showReachedMaxMessage = true;

      } else {
        this.currentPromptGroup = {};
        this.promptGroupDialogMode = 'all';
      }
    },
    closeModal() {
      this.showReachedMaxMessage = false;
    },
    getPromptGroups() {
      return new Promise((resolve, reject) => {
        if (this.userInfo?.client?.clientid !== null) {
          this.$api
            .getAiPromptGroups()
            .then((res) => {
              this.promptGroups = res?.data.length > 0 ? res.data : [];
              this.loadingPromptGroups = false;
              this.promptGroups.forEach((promptGroup) => {
                this.getPollingStatus(promptGroup)
                this.statusTimer[promptGroup.id] = setInterval(() => { this.getPollingStatus(promptGroup) }, 30000)
              });
              resolve(); // Resolve the promise without any value
            })
            .catch((error) => {
              this.loadingPromptGroups = false;
              reject(error); // Reject the promise with the error
            });
        } else {
          this.loadingPromptGroups = false;
          resolve(); // Resolve the promise without any value if client.clientid is undefined
        }
      });
    },
    getPollingStatus(promptGroup) {
      return new Promise((resolve, reject) => {
        this.$api
          .getPromptPollStatus(promptGroup.id)
          .then((res) => {
            if (res?.data) {
              //this is where I am! need to debug here
              promptGroup.polling_status = res.data;
              if (promptGroup.polling_status == 'polled') {
                clearInterval(this.statusTimer[promptGroup.id]);
              }
            }
            resolve();
          })
          .catch((e) => {
            this.$notifier.error({ message: "An error occurred trying to get polling status", e })
            console.log("clearing interval " + promptGroup.id)
            clearInterval(this.statusTimer[promptGroup.id]);
            reject(e); // Reject the promise with the error
          })
      })
    },
    upsertPromptGroup(aiPromptGroup) {
      if (aiPromptGroup === undefined) {
        return;
      }

      const savedMessage = this.promptGroupDialogMode == 'all' ? "AI polling has started" : "Changes saved";

      this.setInProgress(true);
      this.$api
        .upsertAiPromptGroup(aiPromptGroup)
        .then((res) => {
          res?.data ? this.$notifier.info({ message: savedMessage }) : this.$notifier.error({ message: "Could not save topic" });
          if (res?.data) {
            this.currentPromptGroup.ai_prompt_group_id = res.data;
          }
        })
        .then(() => {
          this.promptGroupDialogMode = '';
          this.getPromptGroups();
          this.fetchPromptGroups();
          this.setAiPromptGroup(this.aiPromptGroups.find((item) => item.id === aiPromptGroup.ai_prompt_group_id));
        })
        .catch((e) => {
          let message;
          if (e?.response?.status == 422) {
            if (e.response.data.includes("topic name")) {
              message = "The topic name must be unique. Please choose a different name and try again.";
            } else {
              message = "This topic already exists. Please edit the existing entity.";
            }
          } else {
            message = "Adding/editing failed.";
          }
          this.$notifier.error({ message, e });
          this.setInProgress(false);
        })
        .finally(() => {
          this.setInProgress(false);
        });
    },
    modelsArray(enginesStr) {
      const engines = enginesStr?.split(", ");
      return engines;
    },
    formattedPeers(promptGroup) {
      return promptGroup?.peers;
      // const peers = promptGroup?.peers?.split(", ");
      // const maxPeersToShow = 3;
      // let formattedString = "";

      // if (peers) {
      //   if (peers.length <= maxPeersToShow) {
      //     formattedString = peers.join(", ");
      //   } else {
      //     formattedString =
      //       peers.slice(0, maxPeersToShow).join(", ") + ` +${peers.length - maxPeersToShow}`;
      //   }
      // }

      // return formattedString;
    },
    async routeOnClick(promptGroup) {
      const aiPromptGroup = this.aiPromptGroups.find((item) => item.id === promptGroup.id);
      this.setAiPromptGroup(aiPromptGroup);
      this.setOpenResponses([0]);
      this.routeTo(this.route.entityTracker);
    },
  },
  computed: {
    ...mapGetters("general", {
      showProgress: "showProgress",
    }),
    ...mapGetters("ai", {
      userInfo: "userInfo",
      aiPromptGroups: "aiPromptGroups",
      aiPromptGroup: "aiPromptGroup",
      maxPrompts: "maxPrompts",
      maxPromptGroups: "maxPromptGroups",
    }),
  },
  setup() {
    const { routeTo } = useRouteHelper();
    return {
      routeTo,
    };
  },
  async mounted() {
    /*
     ** init ref variables
     */
    this.setView("promptGroups");
    this.fetchAccountMax();
    await this.initializeAI({ notifier: this.$notifier }).then(() => {
      this.getPromptGroups();
    });

  },
  beforeUnmount() {
    // Clear the interval when the component is destroyed
    this.statusTimer.forEach((timer) => { clearInterval(timer); })

  },

};
</script>

<style lang="scss" scoped>
@import "@/scss/_mixins.scss";
@import "@/scss/icons.scss";


.prompt-groups {
  --transition-style: .3s ease;
  max-width: calc(97vw - var(--side-menu-width) - var(--gutter-width));

  margin: 2rem 0 0 var(--gutter-width);

  .header-section {
    display: flex;
    justify-content: space-between;
    padding-bottom: 40px;

    h3 {
      color: var(--grey8);
      @include h1-style;
      margin: auto 0;
    }

    .subtitle {
      @include title-styles;
      font-size: 14px;
      color: var(--grey8);
    }
  }

  .welcome-message {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 50px;

  }
}

.prompts {
  position: relative;
  cursor: pointer;

  &:hover .open-prompt-handler:after {
    border-color: var(--icon-blue);
  }

  .prompts-wrapper {
    .prompt {
      padding-bottom: .3rem;
    }
  }

  .more-prompts-indicator {
    margin-top: -1rem;
  }

  .open-prompt-handler {
    border: solid 1px var(--grey3);
    border-radius: 50%;
    padding: .5rem;
    position: absolute;
    top: 8px;
    right: 0;

    &:after {
      border-color: var(--dark-gray);
    }
  }

}

.right-buttons {
  display: flex;
  justify-content: space-between;
  gap: 8px;
  margin-right: 1rem;
}

.table-wrapper {
  padding: 1.5rem;
  border: solid 1px var(--grey3);
  border-radius: 20px;
  background-color: white;

  table {

    thead {
      tr {
        @include body3;

        th {
          color: var(--grey7);
          font-weight: normal;
          border-color: black;

          &.topic {
            padding-left: 20px;
          }
        }
      }
    }

    tbody {
      tr {
        color: var(--grey7);
        @include body3;

        .topic {
          cursor: pointer;
          color: var(--grey9);
          font-family: 'Open Sans';
          font-weight: 500;
          padding-left: 0.1rem;

          .keyword {
            display: flex;
            align-items: flex-start;
            gap: 0.3rem;

            .leave-indicator {
              visibility: hidden;
            }

            &:hover span {
              cursor: pointer;

              .leave-indicator {
                visibility: visible;
              }

              color: var(--icon-blue);
              text-decoration: underline;
            }

            .name-wrapper {
              display: flex;
              flex-direction: column;

              .status {
                color: var(--grey7);
                font-weight: normal;
                margin-top: 3rem;
                font-style: italic;

              }
            }
          }
        }

        td.search-models {
          cursor: pointer;
        }

        td.peers {
          max-width: 16rem;
          cursor: pointer;
          color: val(--grey9);

          .add-peers {
            font-size: 1.175rem;
            margin-top: 0.5rem;
            display: inline-block;
            color: var(--grey8);

            &:hover {
              transition: color var(--transition-style);
            }
          }
        }

        td.prompts {
          color: var(--grey9);
        }

        td.delete-handle {
          padding: 0.6rem 0.5rem 0 0;

          .icons {
            &.toggle-pause {
              margin-top: 3rem
            }

            cursor: pointer;

            &:not(:hover) {
              filter: var(--icon-fade-filter);
            }

            &:hover {
              color: var(--icon-blue);
              transition: all var(--transition-style);
            }
          }
        }

      }
    }

    .editable {
      position: relative;

      .pencil {
        position: absolute;
        bottom: 0;
        right: .2rem;
        opacity: 0;

        &:not(:hover) {
          filter: var(--icon-fade-filter);
          transition: all var(--transition-style);
        }
      }

      &:hover {
        background-color: #eee;
        transition: background-color var(--transition-style);

        .pencil {
          opacity: 1;
          transition: opacity var(--transition-style);
        }
      }
    }
  }
}
</style>
