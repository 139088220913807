import axios from 'axios';
import router from '@/router';
import appConfig from '@/../config/config.json';
const { DateTime } = require("luxon");

/**
 * Holds redirect types. Each key is the redirect type, and the value is an object with the name of the api call to retrieve the url for the redirect.
 * We also set url in the object to undefined for clarity - that will cache the url so we don't need to keep calling the api to get it.
 * Not used but leaving the infrastructure in place in case we need it again.
 */
let redirects = {

};

function callApi(path, data, o = {}) {
    const defaultOptions = { method: 'GET', redirectType: '' };
    o = Object.assign({}, defaultOptions, o);

    return getApiUrl(o.redirectType).then(url => {
        const fullPath = url + '/' + (o.redirectType === 'parsingTester' ? 'ParsingTester' : 'ajax') + '/' + path;
        const headers = {};
        axios.defaults.withCredentials = true;

        let call = null;
        if (o.method === 'GET') {
            const queryString = fullPath + (data ? '?' + new URLSearchParams(data).toString() : '');
            const limit = 2000;
            if (queryString.length >= limit) {
                // This is enforced by the security layer. Adding here so we catch it earlier.
                return Promise.reject("Query string too long in API request");
            }
            call = axios.get(queryString, { headers });
        } else {
            //DM-2994 - added this check because the header was breaking PUT requests.
            //we need to look into this because these headers are generally valid for puts and may not be for delete
            //I think whether we set this header will depend on the data we're sending, not the method maybe should be an option to callApi
            //https://stackoverflow.com/questions/6306185/can-http-put-request-have-application-x-www-form-urlencoded-as-the-content-type#:~:text=POST%20supports%20application%2Fx%2Dwww,create%20or%20update%20a%20resource
            //  if (o.method === 'POST') {
            //      headers['Content-Type'] = 'application/x-www-form-urlencoded';
            //  }          
            call = axios({ method: o.method, url: fullPath, data, headers });
        }

        return call.catch((e) => {
            if (e.response.status == 401) {
                router.push('/login');
            }
            throw e;
        });
    });
}


function getApiUrl(redirectType) {

    if (!redirectType) {
        return Promise.resolve(appConfig.backend_url);
    }

    if (!(redirectType in redirects)) {
        return Promise.reject(`invalid redirect URL type ${redirectType}`);
    }

    // previously set - just return it
    if (redirects[redirectType].url) {
        return Promise.resolve(redirects[redirectType].url);
    }

    // if this is the first time using this redirect URL, get and set it now
    // TODO this nested recursive call works for now but probably a bad idea to leave this way
    return callApi(redirects[redirectType].api)
        .then((response) => redirects[redirectType].url = response.data)
        .catch((err) => {
            console.error(`Error getting ${redirectType} redirect url`, err);
            return Promise.reject(err);
        });
}

export default {

    deleteAiPromptGroup: (ai_prompt_group_id) => callApi('deleteAiPromptGroup', { ai_prompt_group_id }, { method: 'PUT' }),

    getAiEngineModels: () => callApi('getAiEngineModels'),

    getAiPromptGroupConfig: (ai_prompt_group_id) => callApi('getAiPromptGroupConfig', { ai_prompt_group_id }),

    getAiPromptGroups: () => callApi('getAiPromptGroups'),

    getPromptPollStatus: (ai_prompt_group_id) => callApi('getPromptPollStatus', { ai_prompt_group_id }),

    getAiPromptTemplates: () => callApi('getAiPromptTemplates'),

    getUserInfo: () => callApi('getUserInfo'),

    login: (email, password) => callApi('login', { email, password }),

    tokenlogin: (token) => callApi('tokenLogin', { token }),

    logout: () => callApi('logout'),

    resetPassword: (code, password) => callApi('resetPassword', { code, password }),

    sendPasswordResetEmail: (email) => callApi('sendPasswordResetEmail', { email, isApp: true }),

    upsertAiPromptGroup: (ai_prompt_group) => callApi('upsertAiPromptGroup', { ai_prompt_group }, { method: 'PUT' }),

    mongoGetSummariesByPromptGroup: (ai_prompt_group_id, dateRange) => callApi('mongoGetSummariesByPromptGroup', { ai_prompt_group_id, startDate: formatDate(dateRange[0]), endDate: formatDate(dateRange[1]) }),

    mongoGetSummariesByPromptGroupForDate: (ai_prompt_group_id, date) => callApi('mongoGetSummariesByPromptGroup', { ai_prompt_group_id, startDate: formatDate(date) }),

    mongoGetResponsesByPromptGroup: (ai_prompt_group_id, dateRange) => callApi('mongoGetResponsesByPromptGroup', { ai_prompt_group_id, startDate: formatDate(dateRange[0]), endDate: formatDate(dateRange[1]) }),

    mongoGetResponsesByPromptGroupForDate: (ai_prompt_group_id, date) => callApi('mongoGetResponsesByPromptGroup', { ai_prompt_group_id, startDate: formatDate(date) }),

    getPromptGroupsForClient: () => callApi('getPromptGroupsForClient'),

    getAccountMax: () => callApi('getAccountMax'),

    getSummariesWithTags: (ai_prompt_group_id, dateRange, ai_prompt_template_tag_id) => callApi('getSummariesWithTags', { ai_prompt_group_id, startDate: formatDate(dateRange[0]), endDate: formatDate(dateRange[1]), ai_prompt_template_tag_id }),
    
    getResponsesWithTags: (ai_prompt_group_id, dateRange, ai_prompt_template_tag_id) => callApi('getResponsesWithTags', { ai_prompt_group_id, startDate: formatDate(dateRange[0]), endDate: formatDate(dateRange[1]), ai_prompt_template_tag_id }),
    
    pausePromptGroup: (ai_prompt_group_id) => callApi('pausePromptGroup', { ai_prompt_group_id} ),
    
    unPausePromptGroup: (ai_prompt_group_id) => callApi('unPausePromptGroup', { ai_prompt_group_id} ),

    generateAIReport : ( clientid, aiPromptGroupId, entityName, displayName, aiEngineModelId, modelType, modelDisplayType, reportStartDate, reportEndDate, aiReportLookupType) => callApi('generateAIReport', { clientid, aiPromptGroupId, entityName, displayName, aiEngineModelId, modelType, modelDisplayType, reportStartDate, reportEndDate, aiReportLookupType} ),
    
    getAIReportStatus : ( clientid) => callApi('getAIReportStatus', { clientid} ),
    
    deleteAIReport: (ai_client_report_id) => callApi('deleteAIReport', { ai_client_report_id }, { method: 'DELETE' }),

    getS3BucketFile: (reportPath) => callApi('getS3BucketFile', { reportPath }),

    sendEmailWithAttachment: (email, reportPath) => callApi('sendEmailWithAttachment', { email, reportPath }),

}

function formatDate(date) {
    return DateTime.fromJSDate(date).toFormat('yyyy-MM-dd');
}