import { createWebHistory, createRouter } from "vue-router";
import { pathOf } from "@/composables/useRouteHelper";

import PromptGroups from "@/views/PromptGroups.vue";
import AIReports from "@/views/AIReports.vue";
import Login from "@/views/Login.vue";
import ForgotPassword from "@/views/ForgotPassword.vue";
import ResetPassword from "@/views/ResetPassword.vue";
import EntityAnalysis from "@/views/EntityAnalysis.vue";
import EntityTracker from "@/views/EntityTracker.vue";
import ModelsCompare from "@/views/ModelsCompare.vue";
import PeersCompare from "@/views/PeersCompare.vue";
import TagDrilldown from "@/views/TagDrilldown.vue";
import { ROUTE } from "@/constants";
import storage from "@/utils/storageUtil";

const routes = [
  {
    path: "/:pathMatch(.*)",
    redirect: (to) => {
      if (to.path !== '/') {
        console.debug({ msg: 'redirect called', to });
      }
      return { name: "Client Management" };
    },
  },
  {
    path: ROUTE.promptGroups,
    name: 'Client Management',
    component: PromptGroups,
    meta: { requiresAuth: true },
    props: (route) => ({
      clientid: route.params.clientid,
    }),
  },
  {
    path: ROUTE.entityAnalysis,
    component: EntityAnalysis,
    meta: { requiresAuth: true },
  },
  {
    path: ROUTE.entityTracker,
    component: EntityTracker,
    meta: { requiresAuth: true },
  },
  {
    path: ROUTE.modelsCompare,
    component: ModelsCompare,
    meta: { requiresAuth: true },
  },  
  {
    path: ROUTE.peersCompare,
    component: PeersCompare,
    meta: { requiresAuth: true },
  },
  {
    path: ROUTE.tagDrilldown + '/:routeback',
    component: TagDrilldown,
    props: (route) => ({
      routeback: route.params.routeback,
    }),
    meta: { requiresAuth: true },
  },
  {
    path: ROUTE.login,
    name: "Login",
    component: Login,
  },
  {
    path: ROUTE.forgotPassword,
    name: "ForgotPassword",
    component: ForgotPassword,
    props: (route) => ({
      code: route.params.code,
    }),
  },
  {
    path: ROUTE.resetPassword,
    name: "ResetPassword",
    component: ResetPassword,
    props: (route) => ({
      code: route.params.code,
    }),
  },
  {
    path: ROUTE.aiReports,
    name: 'AI Reports',
    component: AIReports,
    meta: { requiresAuth: true },
    props: (route) => ({
      clientid: route.params.clientid,
    }),
  },  
];

routes.forEach(route => route.path = pathOf(route.path));

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach(async (to, from, next) => {

  // Store the current URL
  if (!to.fullPath.includes(ROUTE.login) && !to.fullPath.includes(ROUTE.forgotPassword) && !to.fullPath.includes(ROUTE.resetPassword)) {
    storage.set('previousUrl', to.fullPath, true);
  }

  next();
});


export default router;